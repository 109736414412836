import React from 'react';
import logo from './logo-alt.svg';

export function Logo() {
    return (
        <img
            src={logo}
            alt="TriVision"
            style={{ maxHeight: '100%', maxWidth: '90%', width: '400px', margin: 'auto' }} />
    );
}

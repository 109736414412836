import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import SuperfosRanders from './SuperfosRanders';
import Login from './Login';
import { useMutation, useQuery } from "@apollo/client";
import { pollIntervalMs } from './constants';
import { getProductionLinesQuery, getNoRejectionReasonEventsQuery, getLastHourProduction, getLast12HoursProduction, } from './api';
import { getRejectionCostsQuery, updateRejectionCostQuery, updateRejectionCostProductTypeNameQuery } from './api';
import RejectionCostContext from './RejectionCostContext';

function App(){
    const [updateRejectionCost] = useMutation(updateRejectionCostQuery);
    const [updateRejectionCostProductTypeName] = useMutation(updateRejectionCostProductTypeNameQuery);
    const rejectionCostsInfo = useQuery(getRejectionCostsQuery);
    if (rejectionCostsInfo.error)
        console.error(`Error fetching production line data:`, rejectionCostsInfo.error);

    const [rejectionCosts, setRejectionCosts] = useState({});
    const setRejectionCost = useCallback(async (productionLine, costPerThousand, rejectionCostProductTypeName) => {
        const variables = { customerId: productionLine.customerId, lineId: productionLine.id, value: rejectionCostProductTypeName };
        const p1 = updateRejectionCost({ variables: { ...variables, value: costPerThousand  }});
        const p2 = updateRejectionCostProductTypeName({ variables: { ...variables, value: rejectionCostProductTypeName } });
        await Promise.all([p1, p2]);
        rejectionCostsInfo.refetch();
    }, [rejectionCostsInfo, updateRejectionCost, updateRejectionCostProductTypeName]);
    const getRejectionCost = useCallback((lineId) =>{
        const result = rejectionCosts[lineId] ?? 500;
        return result;
    }, [rejectionCosts]);


    useEffect(() => {
        const rejectionCosts = (rejectionCostsInfo.data && rejectionCostsInfo.data.production_intelligence_production_lines_settings) || [];
        const updatedRejectionCosts = {};
        for (let { line_id, key, value } of rejectionCosts )
        {
            if (key === 'rejection_cost_per_thousand')
                updatedRejectionCosts[line_id] = parseFloat(value);
        }
        setRejectionCosts(updatedRejectionCosts);
    }, [rejectionCostsInfo])

    useEffect(() => {
        const reloadTimeoutMs = 1 * 60 * 60 * 1000;
        const id = setTimeout(() => window.location.reload(false), reloadTimeoutMs);
      return () => clearTimeout(id);
    }, []);
    
    const lastHourProductionResult = useQuery(getLastHourProduction, { pollInterval: pollIntervalMs });
    const { data: dataLastHourProduction, error: errorLastHourProduction } = lastHourProductionResult;
    if (errorLastHourProduction)
        console.error(`Error fetching total production data:`, errorLastHourProduction);
    const lastHourProduction  = (dataLastHourProduction && dataLastHourProduction.production_intelligence_last_hour_production) || [];

    const totalProductionInfo = useQuery(getLast12HoursProduction, { pollInterval: pollIntervalMs });
    if (totalProductionInfo.error)
        console.error(`Error fetching total production data:`, totalProductionInfo.error);
    const totalProduction   = (totalProductionInfo.data && totalProductionInfo.data.production_intelligence_last_12_hours_production) || [];

    const productionLinesInfo = useQuery(getProductionLinesQuery);
    if (productionLinesInfo.error)
        console.error(`Error fetching production line data:`, productionLinesInfo.error);
    const productionLines = (productionLinesInfo.data && productionLinesInfo.data.production_intelligence_production_lines) || [];

    const noRejectionReasonEventsInfo = useQuery(getNoRejectionReasonEventsQuery, { pollInterval: pollIntervalMs });
    if (noRejectionReasonEventsInfo.error)
        console.error(`Error fetching no rejection reason data:`, noRejectionReasonEventsInfo.error);
    const noRejectionReasonEvents = (noRejectionReasonEventsInfo.data && noRejectionReasonEventsInfo.data.production_intelligence_events) || [];

    return (
        <RejectionCostContext.Provider value={{
            rejectionCosts,
            getRejectionCost,
            setRejectionCost
            }}>
            <div>
                <SuperfosRanders
                    productionLines={productionLines}
                    noRejectionReasonEvents={noRejectionReasonEvents}
                    lastHourProduction={lastHourProduction}
                    totalProduction={totalProduction}
                />
            </div>
        </RejectionCostContext.Provider>
    );
}

function AppWrapper({ isAuthenticated, login, refresh }){
    // TODO: do not use dummy approach below...
    const [autoRefreshInProgress, setAutoRefreshInProgress] = useState(true);
    const [isAuth, setIsAuth] = useState(false);
    const [loginError, setLoginError] = useState(null);
    useEffect(() => {
        const attemptRefreshFromLocalstorage = async () => {
            const refreshToken = localStorage.getItem('refreshToken');
            if (!refreshToken || isAuth){
                setAutoRefreshInProgress(false);
                return;
            }
            const res = await refresh(refreshToken);
            setIsAuth(res !== null);
            setAutoRefreshInProgress(false);
        };
        attemptRefreshFromLocalstorage();
    }, [refresh, isAuth]);
    if (autoRefreshInProgress)
        return <div />;

    const login2 = async (username, password) => {
        const res = await login(username, password);
        if (!res)
            setLoginError('Failed to login, please check username and password');
        setIsAuth(res !== null);
        return res;
    }
    if (!isAuthenticated && !isAuth)
        return <Login login={login2} loginError={loginError} resetLoginError={() => setLoginError(null)} />;
    return <App />;
}

export default AppWrapper;

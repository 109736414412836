import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // backgroundColor: theme.palette.background.paper,
  },
}));

function ProductionLineList({ productionLines, title, getFormattedState }){
  const classes = useStyles();
  
                        //<ListItemText primary={<span style={{ fontSize: 'large' }}>{d.name}</span>} secondary="Jan 9, 2014" />

    return (
        <div>
            <p style={{ fontSize: 'x-large', textAlign: 'center' }}>{title}</p>
            <List className={classes.root}>
                {productionLines.map((d, i) => 
                    <ListItem key={i} style={{ backgroundColor: '#FFFFFF6F', borderRadius: '2px', margin: '1em', width: '100%' }}>
                        <ListItemAvatar>
                            <Avatar style={{ fontSize: 'xx-large' }}>
                                {i+1}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={<span style={{ fontSize: 'large' }}>{d.name}</span>} />
                        { getFormattedState ? <p>{getFormattedState(d)}</p> : '' }
                    </ListItem>
                )}
            </List>
        </div>
    );
}

export default ProductionLineList;

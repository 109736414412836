import React, { useEffect, useState } from 'react';
import './ProductionLineDialog.css';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField'
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useLazyQuery } from "@apollo/client";
import { getNoRejectionReasonEventsProductionLineQuery, getRejectionCostProductTypeNameQuery } from './api';
import { format, formatDistance, formatRelative } from 'date-fns'
import { da } from 'date-fns/locale'
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@material-ui/core';
import RejectionCostContext from './RejectionCostContext';
import { useContext } from 'react';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function getStatusText({ noProduction, critical, warning }) {
  if (noProduction)
    return 'Ready';
  if (critical)
    return 'Critical';
  if (warning)
    return 'Warning';
  return 'OK';
}

function getColor({ noProduction, critical, warning }) {
  if (noProduction)
    return 'rgb(128,64,64)';
  //return '#0275D8';
  if (critical)
    return '#e15361';
  if (warning)
    return '#ffc107';
  return '#48b461';
}

function getIcon({ noProduction, critical, warning }) {
  if (noProduction)
    return CheckIcon;
  if (critical)
    return CloseIcon;
  if (warning)
    return CloseIcon;
  return CheckIcon;
}

function getRejectionRate(products) {
  if (!Array.isArray(products) || products.length === 0)
    return 0;

  const total = products.reduce((acc, d) => acc + d.product_count, 0);
  const rejected = products.filter(d => d.failed).reduce((acc, d) => acc + d.product_count, 0);
  const rejectionPct = total > 0 ? (100 * rejected / total).toFixed(1) : 0;
  return rejectionPct;
}

function getRejectionCostInfo(rejectionCostProductTypeNameData){
  const loading = rejectionCostProductTypeNameData?.loading;
  const settings = rejectionCostProductTypeNameData?.data?.production_intelligence_production_lines_settings
  if (loading || !settings)
    return { loading, name: '', costPerThousand: 0 };
  const getValue = (settings, key, fallbackValue) => settings.find(s => s.key === key)?.value || fallbackValue;
  const name = getValue(settings, 'rejection_cost_product_type_name', '');
  return { loading, name };
}

function ProductionLineInfoList({ productionLine, onUpdateProductTypeCost, isUpdating = false, rejectionCostProductTypeNameData, costPerThousand  }) {
  const { loading, name } = getRejectionCostInfo(rejectionCostProductTypeNameData);
  const [rejectionCostProductTypeName, setRejectionCostProductTypeName] = useState(name);
  const [rejectionCostPerThousand, setRejectionCostPerThousand] = useState(costPerThousand);
  useEffect(() => {
    if (!loading) {
      setRejectionCostPerThousand(costPerThousand);
      setRejectionCostProductTypeName(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const isRejectionCostProductTypeNameUnchanged = rejectionCostProductTypeName === name;
  const isRejectionCostPerThousandUnchanged = rejectionCostPerThousand === costPerThousand;
  const isRejectionCostUnchanged = isRejectionCostProductTypeNameUnchanged && isRejectionCostPerThousandUnchanged;

  const { status } = productionLine;
  const { active, lastFailedProductTime, lastProductTime, latestProducts, products, } = status;

  if (!active)
    return <NotInterestedIcon fontSize='large' style={{ margin: '5em' }} />;
  const statusText = getStatusText(status);
  const color = getColor(status);
  const StatusIcon = getIcon(status);

  const lastProduct = lastProductTime ? `${formatDistance(lastProductTime, new Date(), { locale: da, addSuffix: true }).toLocaleString()} (${formatRelative(lastProductTime, new Date(), { locale: da })})` : ' mere end 1 time siden';
  const lastFailedProduct = lastFailedProductTime ? `${formatDistance(lastFailedProductTime, new Date(), { locale: da, addSuffix: true }).toLocaleString()} (${formatRelative(lastFailedProductTime, new Date(), { locale: da })})` : ' mere end 12 timer siden';

  const currentRejectionRate = getRejectionRate(latestProducts);
  const totalRejectionRate = getRejectionRate(products);

  return (
    <List component="nav" aria-label="main mailbox folders">
      <ListItem>
        <ListItemIcon>
          <StatusIcon style={{ color }} />
        </ListItemIcon>
        <ListItemText primary={`Status: ${statusText}`} />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <DeleteForeverIcon />
        </ListItemIcon>
        <ListItemText primary={`Frasortering sidste time: ${currentRejectionRate} %`} />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <DeleteForeverIcon />
        </ListItemIcon>
        <ListItemText primary={`Frasortering sidste 12 timer: ${totalRejectionRate}%`} />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <AccessTimeIcon />
        </ListItemIcon>
        <ListItemText primary={`Sidste produkt set: ${lastProduct}`} />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <AccessAlarmIcon />
        </ListItemIcon>
        <ListItemText primary={`Sidste frasorterede produkt set: ${lastFailedProduct}`} />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <MoneyIcon  />
        </ListItemIcon>
        <ListItemText primary={<b>Pris for 1.000 frasorteringer</b>} />
      </ListItem>
      <ListItem>
        <List style={{width: '100%'}}>
          <ListItem>
            <TextField label='Produkttype' style={{width: '100%'}} onChange={e => setRejectionCostProductTypeName(e.target.value)} value={rejectionCostProductTypeName} />
          </ListItem>
          <ListItem>
            <TextField label='Pris i DKK' style={{width: '100%'}} onChange={e => setRejectionCostPerThousand(e.target.value)} value={rejectionCostPerThousand} />
          </ListItem>
          <ListItem>
            <Button disabled={isRejectionCostUnchanged || isUpdating}  variant='outlined' aria-label="gem"  onClick={() => onUpdateProductTypeCost(rejectionCostProductTypeName, rejectionCostPerThousand)} startIcon={isUpdating ? <CircularProgress  /> : <CheckIcon />} >
              Gem
            </Button>
          </ListItem>
        </List>
      </ListItem>
    </List>
  )
}

function NoRejectionReason({ time, details }) {
  const values = details.map(d => d.value).map(d => JSON.parse(d.replaceAll(`'`, `"`)));
  return (<>
    {values.map((value, i) =>
      <li key={i}>{format(new Date(time), 'dd/MM-yy HH:mm:ss', { locale: da })}: {value.comment?.length === 0 ? '-' : value.comment} ({value.initials?.length === 0 ? 'No initials' : value.initials})</li>
    )}
  </>
  );

}

function NoRejectionReasonList({ loading, reasons }) {
  const noReasons = !reasons?.length;
  const latestReason = noReasons ? '' : `. Seneste for ${formatDistance(new Date(reasons[0].time), new Date(), { locale: da, addSuffix: true }).toLocaleString()}.`;
  const headerText = loading ? '' : `${reasons.length || 'Ingen'} frasorteringsårsag${reasons.length !== 1 ? 'er' : ''}${latestReason}`;
  return (
    <Accordion disabled={loading || noReasons}>
      <AccordionSummary
        expandIcon={loading ? <CircularProgress /> : <ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{headerText}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ul>
          {reasons.map((reason, i) => <NoRejectionReason key={i} {...reason} />)}
        </ul>
      </AccordionDetails>
    </Accordion>
  )
}

export default function CustomizedDialogs({ productionLine, open, onClose, onClosed }) {
  const rejectionCostInfo = useContext(RejectionCostContext);
  const [getRejectionCostProductTypeName, getRejectionCostProductTypeNameResult]  = useLazyQuery(getRejectionCostProductTypeNameQuery);
  const [getNoRejectionReasonEventsProductionLine, noRejectionReasonEventsResult]  = useLazyQuery(getNoRejectionReasonEventsProductionLineQuery );
  const [isUpdating, setIsUpdating] = useState(false);
  if (noRejectionReasonEventsResult.error)
    console.error(noRejectionReasonEventsResult.error);
  const reasons = (noRejectionReasonEventsResult.data?.production_intelligence_events || []).sort((a, b) => new Date(a.time) < new Date(b.time) ? 1 : -1) ;
  useEffect(() => {
    if (productionLine?.id == null)
      return;
    getNoRejectionReasonEventsProductionLine({ variables: { lineId: productionLine.id }, });
    getRejectionCostProductTypeName({ variables: { lineId: productionLine.id }, });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionLine]);
  const onUpdateProductTypeCost = async (rejectionCostProductTypeName, rejectionCostPerThousand) => {
    setIsUpdating(true);
    await rejectionCostInfo.setRejectionCost(productionLine, rejectionCostPerThousand, rejectionCostProductTypeName) ;
    try {
      const p1 = getNoRejectionReasonEventsProductionLine({ variables: { customerId: productionLine.customerId, lineId: productionLine.id }, });
      const p2 = getRejectionCostProductTypeName({ variables: { customerId: productionLine.customerId, lineId: productionLine.id }, });
      await Promise.all([p1, p2]);
    }
    catch(e){
      console.error(e);
    }
    setIsUpdating(false);
  };
  const costPerThousand = productionLine ? rejectionCostInfo.getRejectionCost(productionLine.id) : 0;
  return (
    <Dialog id="dlg" onClose={onClose} aria-labelledby="customized-dialog-title" open={open} TransitionProps={{ onExited: onClosed }} >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {productionLine ? productionLine.name : 'Unknown'}
      </DialogTitle>
      <DialogContent id="dlg-content" dividers>
        <Typography component={'span'} gutterBottom>
          <ProductionLineInfoList
            productionLine={productionLine}
            onUpdateProductTypeCost={onUpdateProductTypeCost}
            isUpdating={isUpdating}
            rejectionCostProductTypeNameData={getRejectionCostProductTypeNameResult}
            costPerThousand={costPerThousand}
          />
        </Typography>
        <NoRejectionReasonList loading={noRejectionReasonEventsResult.loading} reasons={reasons} />
      </DialogContent>
      <DialogActions id="dlg-actions">
        <Button autoFocus onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};


const hall61 = {
    name: '61',
    productionLines: [
        { name: 'S001' },
        { name: 'S010' },
        { name: 'S144', noVision: true },
        { name: 'S121', noVision: true },
        { name: 'S004' },
        { name: 'S108', noVision: true },
        { name: 'S136' },
        { name: 'S097', noVision: true },
        { name: 'S135' },
        { name: 'S094', noVision: true },
        { name: 'S134' },
        { name: 'S131', noVision: true },
    ]
};
const hall41 = {
    name: '41',
    productionLines: [
        { name: 'S019' },
        { name: 'S102', noVision: true },
        { name: 'S002' },
        { name: 'S092', noVision: true },
        { name: 'S022', },
        { name: 'S095', noVision: true },
        { name: 'S008' },
        { name: 'S141' },
        { name: 'S118' },
        { name: 'S096', noVision: true },
        { name: 'S106', noVision: true },
        { name: 'S089', noVision: true },
        { name: 'S071', noVision: true },
        { name: 'S100', noVision: true },
        { name: 'S020' },
        { name: 'S098', noVision: true },
    ]
}
const hall39 = {
    name: '39',
    productionLines: [
        { name: 'S107', noVision: true },
        { name: 'S090', noVision: true },
        { name: 'S119', noVision: true },
        { name: 'S114', noVision: true },
        { name: 'S009' },
        { name: 'S068', noVision: true },
        { name: 'S133', noVision: true },
        { name: 'S113', noVision: true },
        { name: 'S105', noVision: true },
        { name: 'empty1', empty: true },
        { name: 'empty1', empty: true },
        { name: 'empty1', empty: true },
        { name: 'S003', noVision: true },
        { name: 'S021', },
    ]
};
const hall21 = {
    name: '21',
    productionLines:[
        { name: 'empty1', empty: true },
        { name: 'empty1', empty: true },
        { name: 'empty1', empty: true },
        { name: 'empty1', empty: true },
        { name: 'empty1', empty: true },
        { name: 'empty1', empty: true },
        { name: 'empty1', empty: true },
        { name: 'empty1', empty: true },
        { name: 'empty1', empty: true },
        { name: 'empty1', empty: true },
        { name: 'empty1', empty: true },
        { name: 'empty1', empty: true },
        { name: 'S129', noVision: true },
        { name: 'empty1', empty: true },
        { name: 'empty1', empty: true },
        { name: 'empty1', empty: true },
    ] /*[
        { name: 'S129', noVision: true },
        { name: 'S053', noVision: true },
        { name: 'S126', noVision: true },
        { name: 'S092', noVision: true },
        { name: 'S068', noVision: true },
        { name: 'S095', noVision: true },
        { name: 'empty2', empty: true },
        { name: 'empty3', empty: true },
        { name: 'empty4', empty: true },
        { name: 'S121', noVision: true },
        { name: 'S122', noVision: true },
        { name: 'empty5', empty: true },
        { name: 'S094', noVision: true },
        { name: 'S138', noVision: true },
        { name: 'S123', noVision: true },
        { name: 'S131', noVision: true },
    ]*/
};
const hall83 = {
    name: '83',
    productionLines: [
        { name: 'S016' },
        { name: 'S014' },
        { name: 'S017' },
        { name: 'S005-1' },
        { name: 'S005-2' },
        { name: 'S015' }, 
        { name: 'S150' },
        { name: 'S149' },
    ]
};
const hall38 = {
    name: '38',
    productionLines: [
        { name: 'S147' },
        { name: 'S011' },
        { name: 'S142' },
        { name: 'S012' },
        { name: 'S145' },
        { name: 'S146' },
        { name: 'S143' },
        { name: 'S148' },
    ]
};
const productionHalls = [hall61, hall41, hall39, hall21, hall83, hall38];

export default productionHalls;

import React, { useState, useEffect, useCallback } from 'react';
import './SuperfosRanders.css';
import productionHalls from './SuperfosRandersProductionHalls';
import ProductionHall from './ProductionHall';
import ProductionLineDialog from './ProductionLineDialog';
import ProductionLineList from './ProductionLineList';
import differenceInSeconds from 'date-fns/differenceInSeconds'
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { formatDistance, } from 'date-fns'
import { da } from 'date-fns/locale'
import { Logo } from './Logo';

function getStatus(name, settings, productionLines = [], noRejectionReasonEvents = [], lastHourProduction = [], totalProduction = []) {
    const { criticalTimeSinceLastFailedProductSeconds, warningTimeSinceLastFailedProductSeconds } = settings;
    const productionLine = productionLines.find(p => name === p.name);
    const id = productionLine ? productionLine.id : null;
    const noRejectionReasons = productionLine ? noRejectionReasonEvents.filter(({ line_id }) => line_id === id) : [];

    const active = !!productionLine;
    const latestProductionLineProducts = lastHourProduction.filter(({ production_line }) => production_line.name === name);
    const productionLineProducts = totalProduction.filter(({ production_line }) => production_line.name === name);

    const getLatest = key => (s, d) => !s || new Date(d[key]) > s ? new Date(d[key]) : s;
    // If last product is more than an hour ago, then the production line is not active, so only look one hour back
    const lastProductTime = latestProductionLineProducts.reduce(getLatest('last_product'), null);
    // If production line is active, and a failed product is more than an hour ago, then it is not found in latestProductionLineProducts, so look in productionLineProducts
    const lastFailedProductTime = productionLineProducts.filter(d => d.failed).reduce(getLatest('last_product'), null);
    const lastNoRejectionAcknowledgementEvent = noRejectionReasons.reduce(getLatest('time'), null);

    const noProduction = !lastProductTime;
    const timeSinceLastFailedProductSeconds = lastFailedProductTime ? differenceInSeconds(new Date(), lastFailedProductTime) : criticalTimeSinceLastFailedProductSeconds + 1;
    const timeSinceLastNoRejectionAcknowledgementSeconds = lastNoRejectionAcknowledgementEvent ? differenceInSeconds(new Date(), lastNoRejectionAcknowledgementEvent) : timeSinceLastFailedProductSeconds;
    const compareTimeSeconds = Math.min(timeSinceLastFailedProductSeconds, timeSinceLastNoRejectionAcknowledgementSeconds);
    const warning = compareTimeSeconds > warningTimeSinceLastFailedProductSeconds;
    const critical = compareTimeSeconds > criticalTimeSinceLastFailedProductSeconds;

    const total = latestProductionLineProducts.reduce((acc, d) => acc + d.product_count, 0);
    const rejected = latestProductionLineProducts.filter(d => d.failed).reduce((acc, d) => acc + d.product_count, 0);
    const currentRejectionRate = total > 0 ? (100 * rejected / total).toFixed(1) : 0;

    return {
        active,
        latestProducts: latestProductionLineProducts,
        products: productionLineProducts,
        noRejectionReasons,
        lastProductTime,
        lastFailedProductTime,
        warning,
        critical,
        noProduction,
        currentRejectionRate,
    }
}

function ProductionLineLists({ productionLinesWithStatus }) {
    const topLongestTimeSinceRejection = productionLinesWithStatus
        .filter(d => d.status.lastFailedProductTime && !d.status.noProduction && (d.status.warning || d.status.critical))
        .sort((a, b) => new Date(a.status.lastFailedProductTime) - new Date(b.status.lastFailedProductTime))
        .slice(0, 3);
    const getLastRejectionTime = d => d.status.lastFailedProductTime ? formatDistance(d.status.lastFailedProductTime, new Date(), { locale: da }) + ' siden' : '';
    const topHighestRejection = productionLinesWithStatus
        .filter(d => d.status.currentRejectionRate)
        .sort((a, b) => b.status.currentRejectionRate - a.status.currentRejectionRate)
        .slice(0, 3);
    const getRejectionRate = d => d.status.currentRejectionRate ? `${d.status.currentRejectionRate}%` : 0;
    return (
        <div className='production-line-lists'>
            <div className='top-ejections'>
                <ProductionLineList productionLines={topHighestRejection} getFormattedState={getRejectionRate} title='Højeste frasorteringer' />
            </div>
            <div className='top-no-ejections'>
                <ProductionLineList productionLines={topLongestTimeSinceRejection} getFormattedState={getLastRejectionTime} title='Tid siden frasortering' />
            </div>
        </div>
    );
}

function DebugSettings({ settings, updateSettingCriticalTime, updateSettingWarningTime, isPolling, startPolling, stopPolling  }) {
    const [showSettings, setShowSettings] = useState(false);
    return (
        <>
            <Checkbox
                style={{ position: 'absolute', bottom: 0, right: 0 }}
                checked={showSettings}
                onChange={() => setShowSettings(!showSettings)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            {showSettings &&
                <div style={{ position: 'absolute', bottom: '1em', left: '1em' }}>
                    <TextField
                        id="standard-number"
                        label="Warning threshold (minutes)"
                        type="number"
                        value={settings.warningTimeSinceLastFailedProductSeconds}
                        onChange={updateSettingWarningTime}
                        InputLabelProps={{ shrink: true, }}
                    />
                    <TextField
                        id="standard-number"
                        label="Critical threshold (minutes)"
                        type="number"
                        value={settings.criticalTimeSinceLastFailedProductSeconds}
                        onChange={updateSettingCriticalTime}
                        InputLabelProps={{ shrink: true, }}
                    />
                    { isPolling && <button onClick={stopPolling}>Stop</button> }
                    { !isPolling && <button onClick={startPolling}>Start</button> }
                </div>
            }
        </>
    )
}

// productionLines: List of production lines in database (subset of production lines in production hall)
// noRejectionReasonEvents: Latest event where operator has acknowledged that there were no rejection
// lastHourProduction: The products from the last hour (grouped by production_line, hour, failed)
// totalProduction: The products from the last 12 hours (grouped by production_line, hour, failed)
function SuperfosRanders({ productionLines, noRejectionReasonEvents, lastHourProduction, totalProduction, isPolling, startPolling, stopPolling }) {
    const [settings, setSettings] = useState({
        criticalTimeSinceLastFailedProductSeconds: 20 * 60,
        warningTimeSinceLastFailedProductSeconds: 10 * 60
    })
    const updateSettingCriticalTime = e => setSettings({
        ...settings,
        criticalTimeSinceLastFailedProductSeconds: e.target.value,
        warningTimeSinceLastFailedProductSeconds: Math.min(e.target.value - 1, settings.warningTimeSinceLastFailedProductSeconds),
    });
    const updateSettingWarningTime = e => setSettings({
        ...settings,
        warningTimeSinceLastFailedProductSeconds: e.target.value,
        criticalTimeSinceLastFailedProductSeconds: Math.max(e.target.value, settings.criticalTimeSinceLastFailedProductSeconds)
    });
    const getProductionHallsWithStatus = useCallback(() =>
        productionHalls.map(hall => ({
            ...hall,
            productionLines: hall.productionLines.map(productionLine => ({
                ...productionLine,
                id: (productionLines || []).find(p => p.name === productionLine.name)?.id,
                customerId: (productionLines || []).find(p => p.name === productionLine.name)?.customer_id,
                status: getStatus(productionLine.name, settings, productionLines, noRejectionReasonEvents, lastHourProduction, totalProduction)
            }))
        })), [productionLines, noRejectionReasonEvents, settings, lastHourProduction, totalProduction]
    );
    const [productionHallsWithStatus, setProductionHallsWithStatus] = useState(getProductionHallsWithStatus());
    const [productionLine, setProductionLine] = useState(null);
    useEffect(() => setProductionHallsWithStatus(getProductionHallsWithStatus()), [getProductionHallsWithStatus]);
    useEffect(() => {
        const interval = setInterval(() => {
            setProductionHallsWithStatus(getProductionHallsWithStatus());
        }, 5000);
        return () => clearInterval(interval);
    }, [productionLines, noRejectionReasonEvents, settings, getProductionHallsWithStatus]);

    const [isClosingDialog, setIsClosingDialog] = useState(false);
    const open = !isClosingDialog && productionLine !== null;
    const onCloseProductionLineDialog = () => setIsClosingDialog(true);
    const onDialogClosed = () => setProductionLine(null) || setIsClosingDialog(false);

    const productionLinesWithStatus = productionHallsWithStatus.map(d => d.productionLines).reduce((acc, d) => [...acc, ...d], []).filter(d => d.status && d.status.active);
    return (
        <div id='plant'>
            <ProductionLineDialog productionLine={productionLine} open={open} onClose={onCloseProductionLineDialog} onClosed={onDialogClosed} />
            {productionHallsWithStatus.map(productionHall =>
                <ProductionHall
                    key={productionHall.name}
                    {...productionHall}
                    onProductionLineClick={productionLine => setProductionLine(productionLine)}
                />
            )}
            <div className='production-hall' id='logo' style={{ padding: 0 }}>
                <Logo />
            </div>
            <div className='production-hall' id='kpi'>
                <ProductionLineLists productionLinesWithStatus={productionLinesWithStatus} />
                <DebugSettings
                    settings={settings}
                    updateSettingCriticalTime={updateSettingCriticalTime}
                    updateSettingWarningTime={updateSettingWarningTime}
                    isPolling={isPolling}
                    stopPolling={stopPolling}
                    startPolling={startPolling}
                />
            </div>
        </div>
    );
}

export default SuperfosRanders;

import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider, ApolloClient, createHttpLink, split } from '@apollo/client';
import CustomCache from './customCache';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { login, refresh } from './authentication';

const getRemainingTime = d => d && d.tokenExpirationTime ? d.tokenExpirationTime - (new Date()) : 0;
let authResult = null;
const runAutoRenew = async () => {
  // Assumes method is called immediately after token is retrieved AND that the timeout does not change..
  const timeout = getRemainingTime(authResult);
  return setInterval(async () => {
    const refreshToken = authResult.refreshToken  || localStorage.getItem('refreshToken');
    if (!refreshToken){
      console.log(`unable to refresh due to missing refreshToken in authResult and local storage`)
      return;
    }
    try {
      const result = await refresh(refreshToken);
      if (result) {
        authResult = result;
        if (authResult?.refreshToken)
          localStorage.setItem('refreshToken', authResult.refreshToken);
      }
      else
        console.log(`No result returned from refresh`);
    }
    catch (err) {
      console.error(`auth error`, err);
    }
  }, timeout - 5000);
};

async function attemptLogin(username, password){
  const result = await login(username, password);
  if (!result)
    return null;
  authResult = result;
  runAutoRenew();
  return true;
}

async function attemptRefresh(refreshToken){
  const result = await refresh(refreshToken);
  if (!result)
    return null;
  authResult = result;
  runAutoRenew();
  return true;
}


const endpoint = 'https://api.trivision.dk/v1/graphql';

const wsLink = new WebSocketLink({
  uri: `wss://api.trivision.dk/v1/graphql`,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => ({
        headers: {
          authorization: `Bearer ${authResult && authResult.accessToken}`
        }
    })
  }
});
const httpLink = createHttpLink({ uri: endpoint });

const splitLink = split(({ query }) => {
  const definition = getMainDefinition(query);
  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  );
},
  wsLink,
  httpLink,
);

  const authLink = setContext(() => {
    const result = authResult;
    const token = result && result.accessToken ? result.accessToken : null;
    return {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  });

// fetchPolich (https://medium.com/@galen.corey/understanding-apollo-fetch-policies-705b5ad71980):
// cache-first: The default fetch policy for Apollo is cache-first. If you do not set a fetch policy yourself, this is what will be used. It favors quick response times for queries over getting the most up-to-date data. If you don’t expect your data to change, or if you are taking care to explicitly update the cache when changes are made, this is a good option.
// cache-and-network: I have found ‘cache-and-network’ to be a good option for displaying data that gets updated frequently. While the ‘cache first’ policy emphasizes quick responses to queries, ‘cache-and-network’ puts a little more weight on keeping the cache up-to-date with what is on the server. Implementing this fetch policy can be a good fix if you have data that is changing frequently and your queries are returning out-of-date information.
// network-only: If you do not want to risk displaying any out-of-date information from the cache, it may make sense to use a ‘network-only’ fetch policy. This policy favors showing the most up-to-date information over quick responses. Unlike ‘cache-and-network’, this policy will never return possibly outdated information from the cache. It will also keep your cache up-to-date for future queries.
// no-cache: The ‘no-cache’ policy is similar to ‘network-only’, but it skips the step of updating the cache. 
// cache-only: The opposite of no-cache, this fetch policy avoids making any network requests. If the data you are querying is not available in the cache, it will throw an error.
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const client = new ApolloClient({
  link: authLink.concat(splitLink),
  cache: new CustomCache(),
  connectToDevTools: true,
  defaultOptions,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App isAuthenticated={authResult !== null} login={attemptLogin} refresh={attemptRefresh} />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

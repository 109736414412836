import React from 'react';
import ProductionLine from './ProductionLine';

function ProductionHall({ name, productionLines, onProductionLineClick }) {
    return (
        <div className='production-hall' id={`hall-${name}`}>
            <div className='label'>{name}</div>
            {productionLines.map(productionLine => {
                return <ProductionLine
                        key={productionLine.name}
                        {...productionLine}
                        onClick={() => onProductionLineClick(productionLine)}
                    />;
            })}
        </div>
    );
}

export default ProductionHall;
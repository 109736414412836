import { get, set, del } from 'idb-keyval';

const encryptionAlgorithm =  "RSA-OAEP";
const algorithm = { name: encryptionAlgorithm };

async function generateKey() {
    try {
        const keyAlgorithm = {
            ...algorithm,
            modulusLength: 2048,
            publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
            hash: { name: "SHA-256" },
        };
        const key = await window.crypto.subtle.generateKey(keyAlgorithm, false, ["encrypt", "decrypt"]);
        return key;
    }
    catch (err) {
        console.error(err);
    }
    return null;
}

async function getEncryptionKey() {
    const keyName = 'Qm970ik6ntvuJsF8';
    const encryptionKey = await get(keyName);
    if (encryptionKey)
        return encryptionKey;
    const generatedEncryptionKey = await generateKey();
    await set(keyName, generatedEncryptionKey);
    return generatedEncryptionKey;
}

async function encrypt(value, publicKey) {
    try {
        if (!publicKey) {
            const key = await getEncryptionKey();
            publicKey = key.publicKey;
        }
        const encoder = new TextEncoder();
        const encodedValue = encoder.encode(value);
        const encryptedValue = await window.crypto.subtle.encrypt(algorithm, publicKey, encodedValue)
        return encryptedValue;
    }
    catch (err) {
        console.error(err);
    }
    return null;
}

async function decrypt(encryptedValue, privateKey) {
    if (!privateKey) {
        const key = await getEncryptionKey();
        privateKey = key.privateKey;
    }
    try {
        const decryptedValue = await window.crypto.subtle.decrypt(algorithm, privateKey, encryptedValue)
        const decoder = new TextDecoder();
        const decodedValue = decoder.decode(new Uint8Array(decryptedValue));
        return decodedValue;
    }
    catch (err) {
        console.error(err);
    }
    return null;
}

async function setValue(key, value, publicKey) {
    try {
        const encryptedValue = await encrypt(value, publicKey);
        set(key, encryptedValue);
    }
    catch (err) {
        console.error(err);
    }
}

async function removeValue(key) {
    await del(key);
}

async function getValue(key, privateKey) {
    try {
        const encryptedValue = await get(key);
        if (!encryptedValue)
            return null;
        const decryptedValue = decrypt(encryptedValue, privateKey);
        return decryptedValue;
    }
    catch (err) {
        console.error(err);
    }
    return null;
}

export {
    generateKey,
    getEncryptionKey,
    setValue as set,
    removeValue as del,
    getValue as get
};
// https://medium.com/expedia-group-tech/creating-your-own-apollo-cache-for-perf-and-profit-8f786849e5f6
//const { InMemoryCache } = require('apollo-cache-inmemory');

export default class OptimizedInMemoryCache {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
    }

    transformDocument (document){
        return document;
    }

    transformForLink(document){
        return document;
    }

    performTransaction(){
        //console.log('performTransaction')
    }

    reset() {
    }

    write(write) {
    }

    read(query) {
        return null;
    }

    batch(){
    }

    diff(query) {
        return { result: null, complete: false };
    }

    useInitialQuery(query) {
        return false;
    }
}
// Statistics window size
//const productsWindowDays = 0;
//const productsWindowHours = 12 + productsWindowDays  * 24;
//const productsWindowMinutes = 0 + productsWindowHours  * 60;
//const productsWindowSeconds = 0 + productsWindowMinutes  * 60;
//const productsWindowMilliseconds = 0 + productsWindowSeconds  * 1000;
const productsTotalWindowMilliseconds = 12 * 60 * 60 * 1000;
const productsCurrentWindowMilliseconds = 1 * 60 * 60 * 1000;

const pollIntervalMs = 10000;

export {
    //productsWindowDays,
    //productsWindowHours,
    //productsWindowMinutes,
    //productsWindowSeconds,
    productsTotalWindowMilliseconds,
    productsCurrentWindowMilliseconds,
    pollIntervalMs,
}
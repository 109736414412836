import jsrsasign from "jsrsasign";

//const authServerUrl = 'http://localhost:8080/auth/realms/master/protocol/openid-connect/token';
//const authServerUrl = 'https://54.154.117.124:443/auth/realms/master/protocol/openid-connect/token';
const authServerUrl = 'https://auth.trivision.dk/auth/realms/master/protocol/openid-connect/token';

const decodeToken = (rawToken) => {
    const [ headerBase64, payloadBase64, /*signatureBase64*/ ] = rawToken.split(".");
    const header = jsrsasign.b64utos(headerBase64);
    const claims  = jsrsasign.b64utos(payloadBase64);
    const parsedHeader = jsrsasign.jws.JWS.readSafeJSONString(header);
    const parsedClaims  = jsrsasign.jws.JWS.readSafeJSONString(claims);
    if (parsedHeader.typ !== 'JWT')
        return null;
    return parsedClaims;
};

const getCustomerInfo = (decodedToken) => {
    const { trivision } = decodedToken;
    if (!trivision)
        return null;
    const customers = trivision['x-trivision-customers'];
    if (!customers)
        return null;
    // customers is an object, so transform to array, i.e. { 0: { name: 'a', ...}, 1: { name: 'b', ...}, 2: { name: 'c', ...}} => [ { name: 'a', ...}, { name: 'b', ...}, { name: 'c', ...}]
    return Object.values(customers);
};

const getExpirationTime = (decodedToken) => {
    const { exp } = decodedToken;
    return new Date(exp * 1000);
};

export const login = async (username, password) => {
    const clientId = 'tv_pi_frontend_users';
    try {
        const response = await fetch(authServerUrl, {
            method: 'POST',
            body: `client_id=${clientId}&password=${password}&username=${username}&grant_type=password`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });
        const success = response.ok && response.status === 200;
        if (!success)
            return null;
        try {
            const { access_token: accessToken, refresh_token: refreshToken } = await response.json();
            const decodedToken = decodeToken(accessToken);
            if (!decodedToken)
                return null;
            const { name } = decodedToken;
            const customerInfo = getCustomerInfo(decodedToken);
            const tokenExpirationTime = getExpirationTime(decodedToken);

            const decodedRefreshToken = decodeToken(refreshToken);
            const refreshTokenExpirationTime = decodedRefreshToken ? getExpirationTime(decodedRefreshToken) : null;
 
            return {
                accessToken,
                refreshToken,
                customerInfo,
                name,
                tokenExpirationTime,
                refreshTokenExpirationTime
            };
        }
        catch(err){
            console.log('Authentication failed', err);
        }

    }
    catch(err){
        console.log('Failed to get response from server: ', err);
    }
    return null;
};

export const refresh = async (refreshToken) => {
    const clientId = 'tv_pi_frontend_users';
    try {
        const response = await fetch(authServerUrl, {
            method: 'POST',
            body: `client_id=${clientId}&refresh_token=${refreshToken}&grant_type=refresh_token`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });
        const success = response.ok && response.status === 200;
        if (!success)
            return null;
        try {
            const { access_token: accessToken, refresh_token: refreshToken } = await response.json();
            const decodedToken = decodeToken(accessToken);
            if (!decodedToken)
                return null;
            const { name } = decodedToken;
            const customerInfo = getCustomerInfo(decodedToken);
            const tokenExpirationTime = getExpirationTime(decodedToken);

            const decodedRefreshToken = decodeToken(refreshToken);
            const refreshTokenExpirationTime = decodedRefreshToken ? getExpirationTime(decodedRefreshToken) : null;
 
            return {
                accessToken,
                refreshToken,
                customerInfo,
                name,
                tokenExpirationTime,
                refreshTokenExpirationTime
            };
        }
        catch(err){
            console.log('Authentication failed', err);
        }

    }
    catch(err){
        console.log('Failed to get response from server: ', err);
    }
    return null;
};
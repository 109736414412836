import { gql } from '@apollo/client';

//const customerId = 2;

const getProductionLinesQuery = gql`query GetProductionLines {
    production_intelligence_production_lines{
        id
        name
        customer_id
    }
}`

const getLatestProductsQuery  = gql`query GetLatestProducts {
    production_intelligence_products(distinct_on: [line_id, failed], order_by: {line_id: desc, failed: asc, time: desc}) {
        customer_id
        line_id
        failed
        lane
        time
        production_line {
            name
        }
    }
}`;

// i.e. $minTm = "2020-09-21T00:00:00Z"
const getProductsQuery = gql`query GetProducts($minTm: timestamptz!) {
    production_intelligence_products_aggregate_5_minutes(order_by: {customer_id: asc, line_id: asc, tm_first_product: desc}, where: {tm_first_product: {_gte: $minTm}}) {
        customer_id
        line_id
        tm_first_product
        tm_last_product
        total_count
        failed_count
    }
}`;

// customer_id
  //production_intelligence_events(order_by: line_id:desc, time: desc}, distinct_on: line_id, where: { type: { name: { _eq: "No rejections acknowledged"}} } ) {
const getNoRejectionReasonEventsProductionLineQuery = gql`query GetNoRejectionReasonEventsProductionLine($lineId: Int!) {
  production_intelligence_events(
    order_by: {time: desc},
    where: {
      type: { name: { _eq: "No rejections acknowledged" } },
      line_id: { _eq: $lineId }
    }
    limit: 10
    ) {
    line_id
    time
    type {
      name
    }
    details {
      key
      value
    }
  }
}`;

const getNoRejectionReasonEventsQuery = gql`query GetNoRejectionReasonEvents {
  production_intelligence_events(order_by: {line_id: desc, time: desc}, where: {type: {name: {_eq: "No rejections acknowledged"}}}, distinct_on: line_id) {
    line_id
    time
    type { name }
    details {
      key
      value
    }
  }
}`;

const subscribeNoRejectionReasonEventsQuery = gql`subscription SubNoRejectionReasonEvents {
  production_intelligence_events(order_by: {line_id: desc, time: desc}, where: {type: {name: {_eq: "No rejections acknowledged"}}}, distinct_on: line_id) {
    line_id
    time
    type { name }
    details {
      key
      value
    }
  }
}`;

const generateAggregateBody = (type, identifier, queryName) => gql`${type} ${identifier} {
  ${queryName} {
    failed
    first_product
    last_product
    product_count
    production_line{
      id
      name
    }
  }
}`

const generateAggregateQuery = (...args) => generateAggregateBody('query', ...args);
const generateAggregateSubscription = (...args) => generateAggregateBody('subscription', ...args)

const getLastHourProduction = generateAggregateQuery('GetLastHourProduction', 'production_intelligence_last_hour_production');
const subscribeLastHourProduction = generateAggregateSubscription('SubLastHourProduction', 'production_intelligence_last_hour_production');
const getLast12HoursProduction = generateAggregateQuery('SubLast12HoursProduction ', 'production_intelligence_last_12_hours_production ');
const subscribeLast12HoursProduction = generateAggregateSubscription('GetLast12HoursProduction ', 'production_intelligence_last_12_hours_production ');
const getLast24HoursProduction = generateAggregateQuery('SubLast24HoursProduction ', 'production_intelligence_last_24_hours_production ');
const subscribeLast24HoursProduction = generateAggregateSubscription('GetLast24HoursProduction ', 'production_intelligence_last_24_hours_production ');



const updateRejectionCostProductTypeNameQuery = gql`mutation UpdateRejectionCostProductTypeName($customerId: Int!,$lineId: Int!, $value: String!) {
  insert_production_intelligence_production_lines_settings_one(
    object: { key: "rejection_cost_product_type_name", customer_id: $customerId, line_id: $lineId, type: "string", value: $value},
    on_conflict: {constraint: production_lines_settings_pkey, update_columns: value})
  {
    key
    value
  }
}`;

const updateRejectionCostQuery = gql`mutation UpdateRejectionCost($customerId: Int!, $lineId: Int!, $value: String!) {
  insert_production_intelligence_production_lines_settings_one(
    object: { key: "rejection_cost_per_thousand", customer_id: $customerId, line_id: $lineId, type: "number", value: $value},
    on_conflict: {constraint: production_lines_settings_pkey, update_columns: value})
  {
    key
    value
  }
}`;

const getRejectionCostProductTypeNameQuery = gql`query GetRejectionCostProductTypeName($lineId: Int!) {
	production_intelligence_production_lines_settings(
    where: {
      line_id: {_eq: $lineId}
      key: { _eq: "rejection_cost_product_type_name" }
      }
  )
  {
    key
    value
  }
}`;

const getRejectionCostsQuery = gql`query GetRejectionCosts {
	production_intelligence_production_lines_settings(
    where: {
      key: { _eq: "rejection_cost_per_thousand" }
      }
  )
  {
    line_id
    key
    value
  }
}`;

export {
  getProductionLinesQuery,
  getLatestProductsQuery,
  getProductsQuery,
  getNoRejectionReasonEventsProductionLineQuery,
  getNoRejectionReasonEventsQuery,
  subscribeNoRejectionReasonEventsQuery,
  getLastHourProduction,
  subscribeLastHourProduction,
  getLast12HoursProduction,
  subscribeLast12HoursProduction,
  getLast24HoursProduction,
  subscribeLast24HoursProduction,

  updateRejectionCostProductTypeNameQuery,
  updateRejectionCostQuery,
  getRejectionCostProductTypeNameQuery,
  getRejectionCostsQuery,
}


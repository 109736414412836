import React from 'react';

import TrendingDownIcon  from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon  from '@material-ui/icons/TrendingFlat';
import TrendingUpIcon  from '@material-ui/icons/TrendingUp';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { formatDistanceStrict  } from 'date-fns'
import { da } from 'date-fns/locale'
import RejectionCostContext from './RejectionCostContext';
import { useContext } from 'react';

function getWarningClass({ noProduction, critical, warning }){
    if (noProduction)
        return 'ready';
    if (critical) 
        return 'danger';
    if (warning)
        return 'warning';
    return 'ok';
}

function getRejectionRate(products){
    if (!Array.isArray(products) || products.length === 0)
        return 0;

    const total = products.reduce((acc, d) => acc + d.product_count, 0);
    const rejected = products.filter(d => d.failed).reduce((acc, d) => acc + d.product_count, 0);
    const rejectionPct = total > 0 ? (100 * rejected / total).toFixed(1) : 0;
    return rejectionPct * 1;
}

function getRejectionCost(products, productCostPerThousand){
    if (!Array.isArray(products) || products.length === 0)
        return 0;

    const rejected = products.filter(d => d.failed).reduce((acc, d) => acc + d.product_count, 0);
    const rejectionCost = rejected * productCostPerThousand / 1000;
    return rejectionCost;
}

function getTrendingIcon(rejectionCurrentPct, rejectionTotalPct){
    const epsilon = 0.2;
    if (Math.abs(rejectionCurrentPct - rejectionTotalPct) <= epsilon)
        return TrendingFlatIcon;
    if (rejectionCurrentPct > rejectionTotalPct)
        return TrendingUpIcon;
    return TrendingDownIcon;
}

function ProductionLine({ id, name, noVision, empty, onClick, status }) {
    const rejectionCostInfo = useContext(RejectionCostContext);
    if (empty)
        return <div className='production-line-empty'></div>
    const {
        active,
        latestProducts,
        products,
        lastFailedProductTime,
        critical,
        noProduction,
    } = status;
    const noVisionClass = noVision ? ' no-vision' : '';
    const activeClass = active ? '' : ' inactive';
    const warningClass = getWarningClass(status);
    const className = `production-line${noVisionClass}${activeClass} ${warningClass}`;
    const rejectionCurrentPct = getRejectionRate(latestProducts);
    const rejectionTotalPct = getRejectionRate(products);
    const TrendingIcon = getTrendingIcon(rejectionCurrentPct, rejectionTotalPct);
    const showInfo = !noProduction && active && !noVision;
    const costPerThousand = rejectionCostInfo.getRejectionCost(id);
    const rejectionCostDkk = Math.round(getRejectionCost(latestProducts, costPerThousand ));
    return (
        <div
            tabIndex='0'
            role='button'
            onKeyDown={showInfo ? e => ( e.key === 'Enter' || e.key === ' ') && (e.preventDefault() || onClick()) : null }
            className={className}
            id={name}
            onClick={!noVision ? onClick : null}>
            <div className='status'>
                {noVision && <VisibilityOffIcon className='visibility-icon' />}
                { active && noProduction &&
                    <div style={{ margin: 'auto', fontSize: 'large' }}>
                        Ready
                    </div>
                }
                {showInfo && !critical &&
                    <div className='rejection-rate'>
                        <div className='rejection-trend'>
                            <div>
                                <TrendingIcon style={{ fontSize: '1.2em' }} />
                            </div>
                            <div>
                                <div style={{ marginTop: '-0.25em' }}>
                                    <small style={{ fontSize: '0.45em' }}>
                                        {rejectionTotalPct}%
                                    </small>
                                </div>
                                {/*/
                                <div style={{ marginTop: '-0.45em' }}>
                                    <small style={{ fontSize: '0.45em', fontStyle: 'italic' }}>
                                        12 timer
                                    </small>
                                </div>
                                /**/}
                            </div>
                        </div>
                        <div className='rejection-rate-pct'>
                            <div style={{ marginTop: '0.5empx' }}>
                                {rejectionCurrentPct}%
                            </div>
                            {/*/
                            <div style={{ marginTop: '-6px' }}>
                                <small style={{ fontSize: '0.45em', fontStyle: 'italic' }}>
                                    Time
                                </small>
                            </div>
                            /**/}
                        </div>
                        <div className='rejection-rate-cost'>
                            <div style={{ marginTop: '6px' }}>
                                {rejectionCostDkk.toLocaleString()}
                            </div>
                            <div style={{ marginTop: '-12px' }}>
                                <small style={{ fontSize: '0.45em' }}>
                                    DKK
                                </small>
                            </div>
                        </div>
                    </div>
                }
                {showInfo && critical ?
                    lastFailedProductTime ?
                        <div className='last-rejection-info'>
                            <small>Sidste frasortering</small>
                            <div style={{ fontSize: '1.8em' }}>{formatDistanceStrict(lastFailedProductTime, new Date(), { locale: da })}</div>
                            <div>siden</div>
                        </div>
                        : <div style={{ margin: 'auto', fontSize: 'medium' }}> Ingen frasortering </div>
                    :
                    ''}
            </div>
            <div className='name'>
                {name}
            </div>
        </div>
    );
}


export default ProductionLine;